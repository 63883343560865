import React, { useEffect, useState } from "react";
import { Stack } from "basis";

import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  Link,
  Container,
  MobileAppBaner,
} from "../../components";
import { MERCHANT_PAGE_ROUTES, TRANSACTION_STATUS } from "../../core/constants";
import { logger } from "../../core";
import { isBrowser } from "../../utils";
import { retry } from "../../utils/retry";

import { getOutcome } from "../../layout/outcome/utils";
import { OrderConfirmation, OrderDecline, Scene } from "../../layout/outcome";
import { getManualPayment } from "../../layout/checkout/manualPayments";

const Complete = () => {
  let transactionId, token;

  if (isBrowser()) {
    const params = new URLSearchParams(unescape(window.location.search));
    transactionId = params.get("transactionId");
    token = params.get("token");
  }

  const [errorType, setErrorType] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [transaction, setTransaction] = useState();
  const [manualPayment, isManualPayment] = useState();

  useEffect(() => {
    const initiate = async () => {
      if (!transactionId || !token) {
        setErrorType("invalid-request");
        return;
      }

      logger.addContext("transactionId", transactionId);
      try {
        setIsProcessing(true);

        const transaction = await retry({
          fn: () => getOutcome(transactionId, token),
          onRetryBegin: (numAttempt) =>
            numAttempt > 0 &&
            logger.error(`getOutcome(): retry #${numAttempt}`, {
              transactionId,
            }),
          attempts: 10,
          sleepMs: 200,
          sleepMsDelay: [
            1000,
            2000,
            3000,
            4000,
            5000,
            6000,
            7000,
            8000,
            9000,
            10000,
          ],
        });

        if (!transaction) {
          setErrorType("invalid-request");
          return;
        }

        setTransaction(transaction);
      } catch (err) {
        logger.error(err.message);
        setErrorType("invalid-request");
      } finally {
        setIsProcessing(false);
      }
    };

    initiate();
  }, [transactionId, token]);

  useEffect(() => {
    isManualPayment(getManualPayment());
  }, [manualPayment]);

  if (isProcessing || !transaction) {
    return (
      <Scene>
        <LoadingMessage message="Please wait while we process your request.." />
      </Scene>
    );
  }

  if (errorType) {
    return (
      <Scene>
        <ErrorMessage
          type={errorType}
          additionalParams={{
            transactionId: transactionId,
          }}
        />
      </Scene>
    );
  }

  const isApproved =
    transaction?.transactionStatus === TRANSACTION_STATUS.APPROVED ||
    transaction?.transactionStatus === TRANSACTION_STATUS.APPROVED_NO_CALLBACK;

  return (
    <Scene>
      {manualPayment && (
        <Container margin="4 0" padding="2" maxWidth={1100}>
          <Stack gap="6">
            <Flex>
              <Link
                href={MERCHANT_PAGE_ROUTES.OrderDashboard}
                newTab={false}
                blue
              >
                &lt; Back to dashboard
              </Link>
            </Flex>
          </Stack>
        </Container>
      )}

      {isApproved && <OrderConfirmation transaction={transaction} />}
      {!isApproved && <OrderDecline transaction={transaction} />}

      <Container bg="grey.t03" margin="4 0" maxWidth={1100} shadow>
        <Container bg="grey.t03" margin="4 0" padding="4 6" maxWidth={800}>
          <MobileAppBaner />
        </Container>
      </Container>
    </Scene>
  );
};

export default Complete;
