import {
  get,
  ENDPOINTS,
  hydratePromotionInfo,
} from "../../../services/checkout";

export const getOutcome = async (transactionId, token) => {
  let response = await get({
    endpoint: ENDPOINTS.PAYMENT_OUTCOME,
    token,
    params: {
      transactionId,
    },
  });

  // add promotion description and selected promotion
  response = hydratePromotionInfo(response);

  return response;
};
