import React from "react";
import { Text } from "basis";

import { Container, Flex } from "../../components";
import { OrderDetails } from "../merchant/components";
import { LogoIcon } from "./components";
import { TRANSACTION_STATUS } from "../../core/constants";

const defaultDeclineText =
  "The transaction was declined. Please confirm your card details, you can log onto the Latitude mobile app or check your latest statement to confirm your available spend amount.";

const fraudDeclineText =
  "The transaction was declined. Please try again later.";

export const OrderDecline = ({ transaction }) => {
  const declineText =
    transaction.transactionStatus === TRANSACTION_STATUS.REJECTED_FRAUD
      ? fraudDeclineText
      : defaultDeclineText;

  return (
    <>
      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={800}>
          <Flex justify="space-around">
            <LogoIcon currency={transaction.currency} />
          </Flex>

          <Text textStyle="heading5" align="center" margin="5 0">
            Purchase declined
          </Text>

          <Text align="center">{declineText}</Text>

          <Text textStyle="heading5" margin="6 0" align="center">
            Order Summary
          </Text>

          <OrderDetails transaction={transaction} />
        </Container>
      </Container>
    </>
  );
};
