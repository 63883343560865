import React from "react";
import styled from "styled-components";

const StyledButtonHolder = styled.div`
  min-width: 358px;
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;

export const ButtonHolder = ({ children }) => {
  return <StyledButtonHolder>{children}</StyledButtonHolder>;
};
