import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "basis";

import {
  Container,
  PromotionDescription,
  Loading,
  Flex,
} from "../../components";
import { APPLE_GROUPS, BRAND, CURRENCY } from "../../core/constants";
import { OrderDetails } from "../merchant/components";
import { LogoIcon } from "./components";

const PlanDescriptionContainer = styled.div`
  text-align: center;
`;

export const OrderConfirmation = ({ transaction }) => {
  const [brandName, setBrandName] = useState(BRAND[CURRENCY.AUD]);
  const [loading, setLoading] = useState(true);

  const hideDescription = APPLE_GROUPS.includes(transaction?.merchantGroup);

  useEffect(() => {
    if (transaction.currency === CURRENCY.NZD) {
      setBrandName(BRAND[CURRENCY.NZD]);
    }
    setLoading(false);
  }, [transaction]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={800}>
          <Flex justify="space-around">
            <LogoIcon currency={transaction.currency} />
          </Flex>

          <Text textStyle="heading5" align="center" margin="4 0">
            Purchase confirmation
          </Text>

          <Text align="center">
            Thanks for shopping Interest Free with {brandName}! Your order with{" "}
            {transaction.tradingName} has been confirmed.
          </Text>

          <Text textStyle="heading5" margin="6 0" align="center">
            Order Summary
          </Text>

          <OrderDetails transaction={transaction} />
        </Container>
      </Container>

      <Container
        bg="secondary.lightBlue.t25"
        margin="4 0"
        maxWidth={1100}
        shadow
      >
        <Container
          bg="secondary.lightBlue.t25"
          margin="4 0"
          padding="4 6"
          maxWidth={800}
        >
          <>
            <Text textStyle="heading5" align="center">
              Payment Plan
            </Text>

            <PlanDescriptionContainer>
              <Text margin="4 0 2 0">
                {transaction?.promotionDescription ?? ""}
              </Text>

              {!hideDescription && (
                <PromotionDescription
                  promotion={transaction?.selectedPromotion}
                  order={transaction}
                />
              )}
            </PlanDescriptionContainer>
          </>
        </Container>
      </Container>
    </>
  );
};
