import React from "react";
import { Icon } from "../../../components";
import { CURRENCY } from "../../../core/constants";

export const LogoIcon = ({ currency }) => {
  const iconType =
    currency === CURRENCY.NZD ? "gemInterestFree" : "latitudeInterestFree";

  return <Icon size="250px" type={iconType} />;
};
